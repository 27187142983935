
import { Vue, Component } from "vue-property-decorator";
import LoginRegister from "@/modules/register/components/LoginRegister.vue";
import BSection from "@/components/bs/BSection.vue";
import BRow from "@/components/bs/BRow.vue";
import BCol from "@/components/bs/BCol.vue";

@Component({
  name: "register",
  components: {
    BCol,
    BRow,
    BSection,
    LoginRegister,
  },
})
export default class RegisterIndex extends Vue {
  bLogin = false;
}
